<template>
  <form :class="$style.form" @submit.prevent="submitForm">
    <div :class="$style.buttons">
      <adw-button
        small
        :link="
          getRoute({
            route: $options.ADDSELLERS_ADMIN_ROUTES.ORDERS.ADDSELLERS.VIEW,
            params: { id: $route.params.id },
          })
        "
      >
        Просмотр заказа
      </adw-button>
      <adw-button v-if="!moySkladId" small @click="createMsOrderById"
        >Создать заказ в МС</adw-button
      >
    </div>

    <br />
    <br />
    <h2>Клиент</h2>
    <div :class="$style.dealer">
      {{ dealerAccount.name }}<br />
      ID {{ dealerAccount.id }}
    </div>
    <label class="label">Склад</label>
    <Select
      :class="$style.select"
      v-model="warehouse"
      :options="selectWarehouse"
      :disabled="!!products.length"
      placeholder="Выберите склад"
    />
    <label class="label">Реквизиты клиента</label>
    <Select
      :class="$style.select"
      v-model="clientRequesiteId"
      :options="selectClientRequesite"
      placeholder="Выберите реквизиты клиента"
      :error="errors.clientRequesite"
      nameField="clientRequesiteId"
      with-trash-icon
      @resetValue="resetSelect"
      @change="setDeliveryPrice"
    />
    <label class="label">Условия доставки</label>
    <Select
      :class="$style.select"
      v-model="deliveryType"
      :options="selectDeliveryType"
      placeholder="Выберите тип доставки"
      nameField="deliveryType"
      with-trash-icon
      @resetValue="resetSelect"
      @change="setDeliveryPrice"
    />
    <div
      v-if="deliveryType !== $options.DELIVERY_TYPE.SELFDELIVERY"
      :class="$style.exressDeliveryBlock"
    >
      <label class="label">Экспресс доставка</label>
      <Checkbox
        :checked="isExpressDelivery"
        @change="handleChangeExpressDelivery"
      />
    </div>
    <div v-if="deliveryType !== $options.DELIVERY_TYPE.SELFDELIVERY">
      <label class="label">Адрес доставки</label>
      <Select
        :class="$style.select"
        v-model="deliveryAddressId"
        :options="selectDeliveryAddress"
        :error="errors.deliveryAddress"
        nameField="deliveryAddressId"
        placeholder="Выберите адрес доставки"
        with-trash-icon
        @change="handleChangeDeliveryAdress"
        @resetValue="resetSelect"
      />
    </div>
    <div
      v-if="
        isAddressNotInsideInPolygon &&
        deliveryType !== $options.DELIVERY_TYPE.SELFDELIVERY
      "
      :class="$style.deliveryPrice"
    >
      <label class="label">Цена доставки для доставки вне полигона</label>
      <input v-model.number="deliveryPrice" />
    </div>
    <label class="label">Получатель</label>
    <Select
      :class="$style.select"
      v-model="selectRecipientId"
      :options="selectRecipient"
      nameField="selectRecipientId"
      placeholder="Выберите получателя"
      :error="errors.selectRecipient"
      with-trash-icon
      @resetValue="resetSelect"
      @change="setDeliveryPrice"
    />
    <br />
    <p :class="$style.help">
      Список получателей формируется из доступных адресов доставки клиента.
    </p>
    <label class="label">Способ оплаты</label>
    <Select
      :class="$style.select"
      v-model="payment"
      :options="selectPayment"
      with-trash-icon
      placeholder="Выберите способ оплаты"
    />
    <AdwCalendar
      v-if="deliveryType === $options.DELIVERY_TYPE.DELIVERY"
      id="orderDatePicker"
      :value="deliveryAt"
      :disabled-dates="disabledDates"
      minimum-view="day"
      maximum-view="day"
      placeholder="Дата доставки"
      is-hide-icon
      :class="$style.datePicker"
      @selected="deliveryAt = $event"
    />
    <div v-if="!products.length" :class="$style.noProducts">
      Не добавлено ни одного товара
    </div>
    <ul v-else :class="$style.products">
      <li
        v-for="(product, index) in products"
        :key="index"
        :class="$style.product"
      >
        <div :class="$style.column">
          Артикул: {{ product.product.vendorСode }}
        </div>
        <div :class="$style.column" :title="product.name">
          {{
            product.product.name.length > 25
              ? product.product.name.substr(0, 25) + '...'
              : product.product.name
          }}
        </div>
        <div :class="$style.column">
          <label class="label">Кол-во</label>
          <input
            v-model.number="product.quantity"
            type="number"
            :min="1"
            :max="
              setMaxQuantity(
                warehouse,
                product.product,
                product.initialQuantity ?? 0,
              )
            "
            @input="checkPricePolicyPrice(index)"
            @change="
              resetExpressDelivery(), handleChangeProductQuantity(product)
            "
          />
        </div>
        <div :class="$style.column">{{ product.productPrice }}</div>
        <div :class="$style.column">
          {{ product.productPrice * product.quantity }}
          ₽
        </div>
        <div :class="$style.column">
          <a
            :class="$style.remove"
            href=""
            title="Удалить товар"
            @click.prevent="removeProduct(index)"
          >
            <!-- prettier-ignore -->
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
              <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
          </svg>
          </a>
        </div>
      </li>
    </ul>
    <div :class="$style.newProduct">
      <h2>Добавление товара</h2>
      <Search
        :class="$style.search"
        v-model.trim="searchItem"
        :items="searchProducts"
        :is-show="isShow"
        :is-pagination="isPagination"
        @clear="clearSearch"
        @scroll="handleScroll"
      >
        <div
          v-for="(item, index) in searchProducts"
          :key="index"
          :class="$style.item"
        >
          <div :class="$style.link">
            <img :src="s3_link + item.mainImage" alt="" />
            <span>{{ item.name }}</span>
            <span>
              Артикул <br />
              {{ item.vendorСode }}
            </span>
            <span>
              Цена <br />
              {{ item.price }}
            </span>
            <span>
              кол-во ИП <br />
              {{ item.quantityInIE }}
            </span>
            <span>
              кол-во ООО <br />
              {{ item.quantityInLLC }}
            </span>
            <span>
              кол-во УС <br />
              {{ item.quantityInRW }}
            </span>
            <span>
              <Button @click="addNewProduct(item)">Добавить</Button>
            </span>
          </div>
        </div>
      </Search>
    </div>
    <div :class="$style.prices">
      <div>Цена доставки: {{ deliveryPrice }}</div>
      <h1 :class="$style.total">Общая сумма: {{ totalPrice }} ₽</h1>
    </div>
    <div :class="$style.actions">
      <Button
        v-if="status === $options.ADDEVENT_ORDER_STATUSES.RESERVED"
        @click="confirmReservedOrder"
        >Подтвердить</Button
      >
      <Button type="submit">Сохранить</Button>
      <Button type="button" @click="$router.back()">Назад</Button>
    </div>
  </form>
</template>

<script>
import delivery from '@/delivery'
import { debounce } from '@/helpers/debounce.js'
import { DELIVERY_TYPE } from '@/constants/deliveryType'
import { WAREHOUSES } from '@/constants/warehouses'
import { PAYMENT_TYPES } from '@/constants/paymentTypes'
import { isPointInsidePolygon } from '@/helpers/addSellers'
import { ADDSELLERS_ADMIN_ROUTES, getRoute } from '@/constants/routing'
import { mapGetters } from 'vuex'
import { ADDEVENT_ORDER_STATUSES } from '@/constants/orderStatuses'
import { DAY_IN_MS } from '@/constants/common'

import Select from '@/components/atoms/Select.vue'
import Search from '@/components/atoms/InputSearchable.vue'
import Button from '@/components/atoms/Button.vue'
import notifications from '@/mixins/notifications'
import Checkbox from '@/components/atoms/Checkbox.vue'

export default {
  ADDEVENT_ORDER_STATUSES,
  components: { Select, Search, Button, Checkbox },
  mixins: [notifications],
  DELIVERY_TYPE,
  WAREHOUSES,
  PAYMENT_TYPES,
  ADDSELLERS_ADMIN_ROUTES,
  data() {
    return {
      isFirstLoadingPage: true,
      isAddressNotInsideInPolygon: false,
      moySkladId: '',
      warehouse: WAREHOUSES.IE,
      clientRequesiteId: null,
      deliveryType: DELIVERY_TYPE.DELIVERY,
      comment: '',
      deliveryAddressId: null,
      deliveryAt: null,
      recipientId: null,
      dealerAccountId: null,
      payment: PAYMENT_TYPES.CASH,
      status: 'PROCESSING',
      products: [],
      settings: {},
      dealerAccount: {
        id: null,
        name: '',
        clientRequesites: [],
        deliveryAddresses: [],
      },
      selectWarehouse: [
        { value: WAREHOUSES.IE, text: 'ИП' },
        { value: WAREHOUSES.LLC, text: 'ООО' },
        { value: WAREHOUSES.RW, text: 'УС' },
        { value: WAREHOUSES.RWC, text: 'УСК' },
      ],
      selectDeliveryType: [
        { value: DELIVERY_TYPE.DELIVERY, text: 'Доставка' },
        { value: DELIVERY_TYPE.DELIVERY_TO_CLIENT, text: 'Доставка клиенту' },
        { value: DELIVERY_TYPE.SELFDELIVERY, text: 'Самовывоз' },
      ],
      selectRecipientId: '',
      selectPayment: [
        { value: PAYMENT_TYPES.CASH, text: 'Наличными при получени' },
        { value: PAYMENT_TYPES.BILL, text: 'На расчетный счет' },
      ],
      errors: {
        clientRequesite: '',
        deliveryAddress: '',
        selectRecipient: '',
        products: [],
      },
      limit: 10,
      searchItem: '',
      searchProducts: [],
      isShow: false,
      isSearch: false,
      isLoading: false,
      isPagination: true,
      page: 1,
      totalPages: 0,
      deliveryPrice: 0,
      isExpressDelivery: false,
    }
  },
  computed: {
    ...mapGetters({
      isRemoteManager: 'isRemoteManager',
    }),
    disabledDates() {
      return { to: new Date(Date.now() - DAY_IN_MS) }
    },
    deliveryAddress() {
      return this.dealerAccount?.deliveryAddresses?.find(
        (item) => item.id === this.deliveryAddressId,
      )
    },
    recipient() {
      return this.dealerAccount?.recipients?.find(
        (item) => item.id === this.selectRecipientId,
      )
    },
    client() {
      return this.dealerAccount?.clientRequesites?.find(
        (item) => item.id === this.clientRequesiteId,
      )
    },
    totalPrice() {
      const productsPrice = this.products.reduce(
        (acc, cur) => cur.productPrice * cur.quantity + acc,
        0,
      )
      return productsPrice + (this.products.length ? this.deliveryPrice : 0)
    },
    s3_link() {
      return process.env.VUE_APP_S3_URL
    },
    selectClientRequesite() {
      return (
        this.dealerAccount?.clientRequesites?.map((item) => ({
          value: item.id,
          text: item.name,
        })) ?? []
      )
    },
    selectDeliveryAddress() {
      return (
        this.dealerAccount?.deliveryAddresses?.map((item) => ({
          value: item.id,
          text: item.cardName,
        })) ?? []
      )
    },
    selectRecipient() {
      return (
        this.dealerAccount?.recipients?.map((item) => ({
          value: item.id,
          text: `${item.lastName} ${item.firstName}  ${item.middleName}`,
        })) ?? []
      )
    },
  },
  watch: {
    async searchItem() {
      this.isShow = true
      await this.handleSearch()
    },
  },
  async created() {
    await this.getSettings()
    await this.getOrder()
    await this.getDealer()
    await this.geocodeAddress()
    this.handleSearch = debounce(this.updateSearchList, 500)
  },
  methods: {
    resetExpressDelivery() {
      if (
        this.isExpressDelivery &&
        this.warehouse === (WAREHOUSES.RW || WAREHOUSES.RWC)
      ) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка не доступна, т.к. в корзине имеются товара с удаленного склада',
          'error',
        )
        return
      }
    },
    resetSelect(resetField) {
      this[resetField] = ''
      if (resetField === 'deliveryAddressId') {
        this.deliveryPrice = 0
        return
      }
      this.setDeliveryPrice()
    },
    handleChangeProductQuantity(product) {
      this.setDeliveryPrice()
      const initialQuantity = product.initialQuantity ?? 0

      if (
        this.warehouse === WAREHOUSES.RW &&
        product.quantity > product.product.quantityInRW + initialQuantity
      ) {
        product.quantity = product.product.quantityInRW + initialQuantity
        return
      }

      if (
        this.warehouse === WAREHOUSES.RWC &&
        product.quantity > product.product.quantityInRWС + initialQuantity
      ) {
        product.quantity = product.product.quantityInRWС + initialQuantity
        return
      }

      if (
        this.warehouse === WAREHOUSES.IE &&
        product.quantity > product.product.quantityInIE + initialQuantity
      ) {
        product.quantity = product.product.quantityInIE + initialQuantity
        return
      }

      if (
        this.warehouse === WAREHOUSES.LLC &&
        product.quantity > product.product.quantityInLLC + initialQuantity
      ) {
        product.quantity = product.product.quantityInLLC + initialQuantity
        return
      }
    },
    async handleChangeExpressDelivery() {
      if (this.warehouse === (WAREHOUSES.RW || WAREHOUSES.RWC)) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка не доступна, т.к. в корзине имеются товара с удаленного склада',
          'error',
        )
        return
      }

      if (!this.settings?.deliveryExpressIsAvailable) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка не доступна',
          'error',
        )
        return
      }

      if (this.isAddressNotInsideInPolygon) {
        this.isExpressDelivery = false
        this.showNotification(
          'К сожалению, экспресс доставка вне полигона не доступна',
          'error',
        )
        return
      }

      this.isExpressDelivery = !this.isExpressDelivery
      await this.setDeliveryPrice()
    },
    async geocodeAddress() {
      const { value, error } =
        await delivery.YandexAPI.GeoCodeActions.geocodeAddress(
          this.deliveryAddress?.city,
          this.deliveryAddress?.street,
        )

      if (error) return

      const isInside = this.isPointInsidePolygon(
        value?.data?.response?.GeoObjectCollection?.featureMember[0]?.GeoObject?.Point?.pos
          .split(' ')
          .reverse(),
        [JSON.parse(this.settings?.polygonValue)],
      )

      if (!isInside && this.isFirstLoadingPage) {
        this.isAddressNotInsideInPolygon = true
        this.isFirstLoadingPage = false
        return
      }

      if (!isInside) {
        this.isAddressNotInsideInPolygon = true
        this.deliveryPrice = 0
        this.showNotification(
          'Адрес доставки не попадает в полигон, но вы можете указать цену самостоятельно в поле цена доставки для доставки вне полигона',
          'warning',
        )
        return
      }

      this.isAddressNotInsideInPolygon = false

      return isInside
    },
    setDeliveryPrice() {
      if (this.deliveryType === DELIVERY_TYPE.SELFDELIVERY) {
        this.isExpressDelivery = false
        this.deliveryPrice = 0
        return
      }

      if (this.deliveryAddress) {
        const price = this.totalDiscountPrice
          ? this.totalDiscountPrice
          : this.totalPrice

        this.recipient
          ? this.setDeliveryPriceToRecipient(price)
          : this.setDeliveryPriceToDealer(price)
      }
    },
    setDeliveryPriceToRecipient(price) {
      if (this.isAddressNotInsideInPolygon) return

      if (!this.settings?.deliveryToClientIsAvailable) return

      const isPriceMoreBorder =
        price > this.settings?.deliveryToClientBorderPrice

      this.deliveryPrice = isPriceMoreBorder
        ? 0
        : this.isExpressDelivery && this.settings?.deliveryExpressIsAvailable
        ? this.settings?.deliveryExpressPrice
        : this.settings?.deliveryToClientPrice
    },
    setDeliveryPriceToDealer(price) {
      if (this.isAddressNotInsideInPolygon) return

      if (!this.settings?.deliveryIsAvailable) return

      const isPriceMoreBorder = price > this.settings?.deliveryBorderPrice

      this.deliveryPrice = isPriceMoreBorder
        ? 0
        : this.isExpressDelivery && this.settings?.deliveryExpressIsAvailable
        ? this.settings?.deliveryExpressPrice
        : this.settings?.deliveryPrice
    },
    async getOrder() {
      const { value, error } = await delivery.AddwineCore.OrdersActions.getById(
        this.$route.params.id,
      )
      if (error) return

      this.setOrder(value)
    },
    setOrder(value) {
      this.warehouse = value.order.warehouse
      this.clientRequesiteId = value.order.clientRequesiteId
      this.deliveryType = value.order.deliveryType
      this.deliveryAddressId = value.order.deliveryAddresId
      this.recipientId = value.order.recipientId
      this.payment = value.order.payment
      this.selectRecipientId = value.order.recipient.id
      this.deliveryPrice = value.order.deliveryPrice
      this.dealerAccountId = value.order.dealerAccountId
      this.isExpressDelivery = value.order.isExpressDelivery
      this.moySkladId = value.order.moySkladId
      this.status = value.order.status
      this.deliveryAt = value.order.deliveryAt

      this.products = value?.orderProducts?.map((product) => {
        return { ...product, initialQuantity: product.quantity }
      })
    },
    async getDealer() {
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getById(this.dealerAccountId)

      if (error) return

      this.dealerAccount = value
    },
    checkPricePolicyPrice(index) {
      if (this.products[index].product?.discountPrices) {
        const discountPrice = this.products[index].discountPrice
        if (
          this.products[index].quantity >= discountPrice.quantity &&
          discountPrice.price > 0
        )
          this.products[index].productPrice = discountPrice.price
        else {
          this.products[index].productPrice =
            this.products[index].priceFromCatalog
        }
      }
    },
    checkRecipient(recipientId) {
      if (this.dealerAccount.recipients.find((x) => x.id === recipientId)) {
        this.errors.selectRecipient = ''
      }
    },
    async handleChangeDeliveryAdress() {
      await this.geocodeAddress()

      this.setDeliveryPrice()
    },
    async addNewProduct(product) {
      const result = await delivery.AddwineCore.ProductsActions.getByVendorCode(
        product.vendorСode,
      )

      if (result.error) {
        console.error(result.error)
        this.showNotification(
          `Товара с артикулом ${product.vendorСode} не существует`,
          'error',
        )
        return
      }

      if (this.warehouse === WAREHOUSES.IE && !result.value.quantityInIE) {
        this.showNotification(
          `Товара с артикулом ${product.vendorСode} нет в наличии на складе ИП`,
          'error',
        )
        return
      }

      if (this.warehouse === WAREHOUSES.LLC && !result.value.quantityInLLC) {
        this.showNotification(
          `Товара с артикулом ${product.vendorСode} нет в наличии на складе ООО`,
          'error',
        )
        return
      }

      if (this.warehouse !== WAREHOUSES.RWC && result.value.supplierId) {
        this.showNotification(
          `Товар доступен только для комиссионной продажи!`,
          'error',
        )
        return
      }

      if (!this.dealerAccount?.pricePolicyId) {
        const defaultPolicy = result.value.discountPrices.find(
          (x) => x.pricePolicy.isDefault === true,
        )
        this.dealerAccount.pricePolicy = defaultPolicy.pricePolicy
        this.dealerAccount.pricePolicyId = defaultPolicy.pricePolicyId
      }

      const discountPrice = result.value.discountPrices.find(
        (x) => x.pricePolicyId === this.dealerAccount.pricePolicyId,
      )
      const productPrice =
        discountPrice.quantity === 1 && discountPrice.price > 0
          ? discountPrice.price
          : result.value.price

      if (!this.products.length) {
        this.products.push({
          quantity: 1,
          product: result.value,
          name: result.value.name,
          productId: result.value.id,
          productPrice: productPrice,
          priceFromCatalog: result.value.price,
          discountPrice,
        })
      } else {
        if (this.products.find((item) => item.productId === result.value.id)) {
          this.showNotification('Такой товар уже был добавлен', 'error')
          return
        }

        if (this.products[0].supplierId == result.value.supplierId) {
          this.products.push({
            quantity: 1,
            product: result.value,
            name: result.value.name,
            productId: result.value.id,
            productPrice: productPrice,
            priceFromCatalog: result.value.price,
            discountPrice,
          })
        } else {
          this.showNotification(
            'У этих товаров разные поставщики. Оформляйте разными заказами',
            'error',
          )
        }
      }
    },
    removeProduct(index) {
      this.products.splice(index, 1)
    },
    resetErrors() {
      this.errors.clientRequesite = ''
      this.errors.deliveryAddress = ''
      this.errors.selectRecipient = ''
      this.errors.products = ''
    },
    validateForm() {
      let success = true
      this.resetErrors()
      if (!this.clientRequesiteId) {
        this.errors.clientRequesite = 'Выберите реквизиты клиента'
        success = false
      }

      if (
        this.deliveryType === DELIVERY_TYPE.DELIVERY &&
        !this.deliveryAddressId
      ) {
        this.errors.deliveryAddress = 'Выберите адрес доставки'
        success = false
      }

      if (!this.selectRecipientId) {
        this.errors.selectRecipient = 'Выберите получателя'
        success = false
      }

      if (!this.products.length) {
        this.errors.products = 'Не добавлено ни одного товара'
        success = false
      }

      return success
    },
    async confirmReservedOrder() {
      const loading = this.$loading({
        lock: true,
      })

      const result = await delivery.AddwineCore.OrdersActions.confirm(
        this.$route.params.id,
        {},
      )
      loading.close()

      if (result.error) {
        this.showNotification('Ошибка подтверждения заказа', 'error')
        return
      }
      await this.getOrder()
      this.showNotification('Заказ успешно подтвержден', 'success')
    },
    async submitForm() {
      if (this.validateForm()) {
        let deliveryAddress = null

        if (!this.clientRequesiteId) {
          this.showNotification('Выберите реквизиты клиента', 'error')
          return
        }
        if (
          this.deliveryType !== DELIVERY_TYPE.SELFDELIVERY &&
          !this.deliveryAddressId
        ) {
          this.showNotification('Выберите адрес доставки', 'error')
          return
        }
        if (!this.selectRecipientId) {
          this.showNotification('Выберите получателя', 'error')
          return
        }
        if (!this.products.length) {
          this.showNotification('Не добавлено ни одного товара', 'error')
          return
        }

        if (!this.payment) {
          this.showNotification('Выберите способ оплаты', 'error')
          return
        }

        if (!this.deliveryType) {
          this.showNotification('Выберите способ доставки', 'error')
          return
        }

        if (this.deliveryType !== DELIVERY_TYPE.SELFDELIVERY) {
          deliveryAddress = {
            id: this.deliveryAddress.id,
            cardName: this.deliveryAddress.cardName,
            city: this.deliveryAddress.city,
            street: this.deliveryAddress.street,
            office: this.deliveryAddress.office,
            comment: this.deliveryAddress.comment,
          }

          this.comment = this.deliveryAddress.comment
        }

        const deliveryPrice =
          this.deliveryType === DELIVERY_TYPE.SELFDELIVERY
            ? 0
            : this.deliveryPrice

        const result = await delivery.AddwineCore.OrdersActions.update(
          this.$route.params.id,
          {
            price: this.totalPrice || 100,
            payment: this.payment,
            warehouse: this.warehouse,
            deliveryType: this.deliveryType,
            comment: this.comment,
            dealerId: this.dealerAccount.id,
            clientRequesiteId: this.clientRequesiteId,
            deliveryAddress: deliveryAddress,
            recipient: this.recipient,
            isExpressDelivery: this.isExpressDelivery,
            deliveryPrice: deliveryPrice,
            deliveryAt: this.deliveryAt,
            products: this.products.map((item) => {
              return {
                quantity: item.quantity,
                name: item.name,
                productId: item.productId,
                productPrice: item.productPrice,
              }
            }),
          },
        )

        if (result.error) {
          console.error(result.error)
          this.showNotification('Ошибка изменения заказа', 'error')
          return
        }

        if (this.isRemoteManager) {
          this.$router.push(
            this.getRoute({
              route: ADDSELLERS_ADMIN_ROUTES.DEALERS.ORDERS.LIST,
              params: { id: this.dealerAccount.id },
            }),
          )
          return
        }
        this.$router.push('/sellers/orders')
      }
    },
    async clearSearch() {
      this.isShow = false
      this.isSearch = false
      this.searchItem = ''
    },
    async getSearchList() {
      const query = {
        limit: this.limit,
        search: this.searchItem,
        page: this.page,
      }

      const result = await delivery.AddwineCore.ProductsActions.getSearchList(
        query,
      )
      if (result.error) {
        this.error = result.error
      } else {
        this.totalPages = Math.ceil(result.value.meta.count / this.limit)
        this.searchProducts = [...this.searchProducts, ...result.value.data]
      }
    },
    setMaxQuantity(warehouse, product, quantity) {
      switch (warehouse) {
        case WAREHOUSES.IE:
          return product.quantityInIE + quantity ?? 0
        case WAREHOUSES.LLC:
          return product.quantityInLLC + quantity ?? 0
        case WAREHOUSES.RW:
          return product.quantityInRW + quantity ?? 0
        case WAREHOUSES.RWC:
          return product.quantityInRW + quantity ?? 0
        default:
          return 1
      }
    },
    async handleScroll(e) {
      if (!this.isLoading) {
        const prevScrollHeight = e.target.scrollHeight
        let scroll = prevScrollHeight - e.target.scrollTop
        if (scroll <= 400 && this.page < this.totalPages) {
          this.page++
          await this.getSearchList()
        }
      }
    },
    async updateSearchList() {
      this.searchProducts = []
      await this.getSearchList()
    },
    async getSettings() {
      const result = await delivery.AddwineCore.SellersSettingsActions.get()
      if (result.error) return

      this.settings = result.value
    },
    isPointInsidePolygon(point, polygons) {
      return isPointInsidePolygon(point, polygons)
    },
    getRoute(route, params) {
      return getRoute(route, params)
    },
    async createMsOrderById() {
      const { error } = await delivery.AddwineCore.OrdersActions.createMsOrder(
        this.$route.params.id,
      )

      if (error) {
        this.showNotification('Ошибка создания заказа в МС', 'error')
        return
      }

      this.showNotification('Заказ в МС успешно создан', 'success')
    },
  },
}
</script>

<style lang="scss" module>
.form {
  padding: 3rem;

  .buttons {
    display: flex;
    gap: 2rem;
  }

  h2 {
    margin-bottom: 0rem;
  }
  .dealer {
    margin-bottom: 1rem;
  }
  .exressDeliveryBlock {
    display: flex;
    gap: 1rem;
  }
  .select {
    & > div {
      color: $black-gray;
    }
  }
  select {
    display: block;
    width: 100%;
    margin-bottom: 1rem;
  }

  .prices {
    display: flex;
    justify-content: space-between;
  }
  .help {
    margin: -1rem 0 1rem;
  }

  .deliveryPrice {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem;
  }

  .newDeliveryAddress {
    display: flex;
    flex-direction: column;
    span {
      color: $error;
    }
  }
  .newRecipient {
    span {
      color: $error;
    }
  }
  .newProduct {
    margin-bottom: 1rem;
    .vendorCode {
      flex: 1 1 auto;
      margin-right: 1rem;
      > input {
        width: 100%;
      }
    }
    > [type='submit'] {
      align-self: flex-end;
    }
  }
  .noProducts {
    text-align: center;
    padding-top: 1rem;
    margin-bottom: 1rem;
    color: $error;
  }

  .datePicker {
    margin: 1rem 0;
  }

  .products {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    .product {
      margin-bottom: 1rem;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      &:nth-child(even) {
        background: $extra-light-gray;
      }
      .column {
        padding: 0.5rem 1rem;
        display: flex;
        align-items: center;
        > label {
          margin-right: 0.5rem;
        }
        .remove {
          color: $error;
        }
        &:last-child {
          justify-content: flex-end;
        }
      }
    }
  }
  .search {
    margin-bottom: 5rem;
    max-width: 80.5rem;
  }
  .item {
    display: flex;
    align-items: center;

    border-bottom: 0.063rem solid $extra-light-gray;
    &:last-child {
      border-bottom: none;
    }
    .link {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      gap: 0 0.5rem;
      text-decoration: none;
      width: 100%;
      padding: 1rem 0;
      color: $black-gray;
      img {
        width: 5rem;
        height: 5rem;
      }
    }
  }
  .total {
    text-align: right;
    margin-bottom: 1rem;
  }
  .actions {
    display: flex;
    gap: 0.5rem;
  }
}
</style>
